import {
  OPINION_LINK,
  OPINION_LINK_LYON,
  OPINION_LINK_NICE
} from '@/constants/global';
import { Opinion, OpinionData } from './interfaces';

export const sortOpinions = (opinions: Opinion[]) =>
  opinions.sort(({ rating }, { rating: ratingNext }) => ratingNext - rating);

export const convertOpinion = (opinionsData: OpinionData[]) =>
  opinionsData.map(({ survey_id, answer, name, rating, comment }) => ({
    id: survey_id,
    date: new Date(answer),
    rating,
    name,
    comment
  }));

export const convertRating = (rating: number) =>
  Math.round(((rating * 5) / 100) * 10) / 10;

export const getOpinionSystemLocation = (location: string) => {
  switch (location) {
    case 'Lyon':
      return OPINION_LINK_LYON;
    case 'Nice':
      return OPINION_LINK_NICE;
    default:
      return OPINION_LINK;
  }
};
