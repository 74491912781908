import { transparentize } from 'polished';
import styled from 'styled-components';

import { RemunerationLayout } from '@/components/common/slices/remuneration/Remuneration.styles';
import {
  SliceContent,
  SliceWrapperFull
} from '@/components/common/slices/Slices.styles';
import { Grid } from '@/utils/styles';
import {
  BagossFont,
  breakpoints,
  colors,
  convertPxToRem
} from '@proprioo/salatim';

export const RecruitmentLayout = styled(SliceWrapperFull)`
  position: relative;
  z-index: 0;
  padding: ${convertPxToRem(56)} 0;
  background: ${colors.green.base};

  @media (min-width: ${breakpoints.base}px) {
    padding: ${convertPxToRem(100)} 0;
  }

  ${SliceContent} {
    ${Grid};
  }

  & + ${RemunerationLayout} {
    padding-top: 0;
  }
`;

export const RecruitmentTitle = styled.div`
  margin: 0 0 ${convertPxToRem(40)};

  @media (min-width: ${breakpoints.base}px) {
    margin-bottom: 0;
  }
`;

export const RecruitmentCta = styled.div`
  margin: ${convertPxToRem(16)} 0 0;
`;

export const RecruitmentTitleWrapper = styled.div`
  position: relative;
  grid-column: 1 / 6;
  background: ${colors.green.base};
  z-index: 2;
  align-items: center;
  justify-content: center;
  display: flex;

  @media (min-width: ${breakpoints.xsmall}px) {
    grid-column: 1 / 5;
  }

  @media (min-width: ${breakpoints.medium}px) {
    grid-column: 1 / 4;
  }

  @media (min-width: ${breakpoints.base}px) {
    grid-column: 1 / 5;
    padding-right: ${convertPxToRem(18)};

    &::after {
      content: '';
      width: ${convertPxToRem(24)};
      height: 100%;
      position: absolute;
      right: -${convertPxToRem(12)};
      top: 0;
      background-color: ${transparentize(0.9, 'black')};
    }
  }
`;

export const ArgumentWrapper = styled.div`
  background-color: ${colors.red.base10};
  background-repeat: no-repeat;
  padding-top: 100%;
  position: relative;
`;

export const ArgumentContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8%;
  color: ${colors.dark.base};

  h5 {
    margin: 0;
  }
`;

export const ArgumentNumberWrapper = styled.p`
  ${BagossFont};
  font-size: ${convertPxToRem(35)};
  font-weight: bold;
  margin: 0;
  line-height: 1;
  margin-bottom: -${convertPxToRem(16)};
`;

export const ArgumentNumber = styled.span`
  font-size: ${convertPxToRem(104)};
  line-height: 1;
`;

export const ArgumentText = styled.p`
  margin: ${convertPxToRem(4)} 0 0;
`;

export const ArgumentCounter = styled.div`
  ${BagossFont};
  color: ${transparentize(0.9, 'black')};
  font-size: ${convertPxToRem(180)};
  position: absolute;
  left: -${convertPxToRem(54)};
  z-index: -1;

  @media (min-width: ${breakpoints.normal}px) {
    font-size: ${convertPxToRem(208)};
  }
`;

export const ArgumentsContainer = styled.div`
  position: relative;
  grid-column: 1 / -1;
  width: 100%;

  @media (min-width: ${breakpoints.base}px) {
    grid-column: 5 / -1;
    min-height: ${convertPxToRem(389)};
  }

  ::before {
    @media (min-width: ${breakpoints.base}px) {
      content: '';
      position: absolute;
      right: calc(100% + ${convertPxToRem(32)});
      top: 0;
      bottom: 0;
      width: 100vw;
      background: ${colors.green.base};
      z-index: 1;
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
    cursor: grab;
    min-width: 100%;
  }

  .slick-slide {
    width: ${convertPxToRem(310)};
    padding: 0 ${convertPxToRem(16)};

    @media (min-width: ${breakpoints.base}px) {
      width: 100%;
      padding-right: ${convertPxToRem(24)};
      padding-left: 5%;
    }

    @media (min-width: ${breakpoints.normal}px) {
      width: 50%;
    }

    :nth-child(2n) {
      padding-top: ${convertPxToRem(70)};

      ${ArgumentWrapper} {
        background-image: url('/static/pictures/pattern-recruit-middle.svg');
        background-position: center;
        background-size: 100% auto;
      }

      ${ArgumentCounter} {
        top: -${convertPxToRem(110)};
      }
    }

    :nth-child(2n + 1) {
      ${ArgumentWrapper} {
        background-image: url('/static/pictures/pattern-recruit-first.svg');
        background-position: top right;
        background-size: 50% auto;
      }

      ${ArgumentCounter} {
        bottom: -${convertPxToRem(114)};
      }
    }
  }
`;
