import { useRouter } from 'next/router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPANY, URLS } from '@/constants/global';

import Breadcrumb from './Breadcrumb';
import { BreadcrumbColor, BreadcrumbElements } from './interfaces';

const excludedPaths = [''];

export const buildElements = (pathname: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  const elements: BreadcrumbElements = {
    ...URLS.HOMEPAGE,
    label: COMPANY
  };

  const path = pathname.split('/').filter(p => !excludedPaths.includes(p));

  let lastChild = elements;

  path.forEach(key => {
    if (key) {
      const url = getUrlFromURLS(key);

      if (url) {
        const { href, label = COMPANY } = url;

        const newLastChild = {
          href,
          label: label === COMPANY ? COMPANY : t(label)
        };

        lastChild.child = newLastChild;
        lastChild = newLastChild;
      }
    }
  });

  return elements;
};

export const getUrlFromURLS = (key: string) => {
  const [findedKey] = Object.keys(URLS).filter(urlKey =>
    URLS[urlKey].href.includes(key)
  );

  if (findedKey) {
    return URLS[findedKey];
  }
};

type UrlBreadcrumbProps = {
  color?: BreadcrumbColor;
};

const UrlBreadcrumb: FC<UrlBreadcrumbProps> = ({
  color = BreadcrumbColor.WHITE
}) => {
  const { pathname } = useRouter();
  const list = buildElements(pathname);

  return <Breadcrumb color={color} list={list} />;
};

export default UrlBreadcrumb;
