import useSWR from 'swr';

import { Opinion, OpinionSystem } from './interfaces';
import { convertOpinion, convertRating, sortOpinions } from './utils';

type OpinionContextProps = {
  opinions: Opinion[];
  nbTotalOpinions?: number;
  rating?: number;
};

export const useOpinionSystem = (): OpinionContextProps => {
  const { data } = useSWR<OpinionSystem>('/api/opinion');

  if (!data?.company.company_rating || !data.survey) {
    return {
      opinions: []
    };
  }

  const [first] = data.company.company_rating;

  return {
    opinions: sortOpinions(convertOpinion(data.survey.collaborator_survey)),
    rating: convertRating(first.rating),
    nbTotalOpinions: first.survey_total
  };
};
