import styled, { css } from 'styled-components';

import { colors, convertPxToRem, fonts } from '@proprioo/salatim';

export const OpinionLayout = styled.div<{ displayOnlyRatingStyle?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: white;

  ${({ displayOnlyRatingStyle }) =>
    displayOnlyRatingStyle &&
    css`
      color: ${colors.terracota.base};
      font-size: ${fonts.bigger};
    `}

  svg {
    width: ${convertPxToRem(68)};
    height: ${convertPxToRem(28)};
  }
`;

export const OpinionRating = styled.p`
  font-weight: bold;
  margin: 0 0 0 ${convertPxToRem(12)};
`;

export const OpinionTotal = styled.p`
  color: white;
  margin: 0 0 0 ${convertPxToRem(3)};
`;
