import Image from 'next/image';
import { RichTextBlock } from 'prismic-reactjs';
import React, { FC, Fragment } from 'react';

export type ImageConf = {
  value: string;
  label?: number;
};

export type ImageProps = {
  imageSrc: RichTextBlock;
  alt?: string;
  dataTest?: string;
  fill?: boolean;
  priority?: boolean;
  sizes?: ImageConf[];
};

const ImagePrismic: FC<ImageProps> = ({
  alt,
  dataTest = 'image',
  imageSrc,
  fill,
  priority,
  sizes
}) => {
  const url = imageSrc.url ? imageSrc.url.split('&')[0] : '';

  if (!url) {
    return <Fragment />;
  }

  const imgSrc = { ...imageSrc, url };

  const sizesConf = sizes
    ?.map(({ label, value }) =>
      label ? `(max-width: ${label}px) ${value}` : `${value}`
    )
    .join(', ');

  return (
    <Image
      alt={imageSrc.alt || alt || ''}
      data-test={dataTest}
      height={!fill ? imgSrc.dimensions?.height : undefined}
      fill={fill}
      priority={priority || false}
      quality={90}
      sizes={sizesConf || '100vw'}
      src={imgSrc.url}
      width={!fill ? imgSrc.dimensions?.width : undefined}
    />
  );
};

export default ImagePrismic;
