import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { RecruitmentLayout } from '@/components/common/slices/recruitment/Recruitment.styles';
import { TypeHeaderImgDisplay } from '@/utils/prismic';
import { Grid, Image } from '@/utils/styles';
import {
  breakpoints,
  colors,
  Container,
  convertPxToRem,
  paragraphs
} from '@proprioo/salatim';

type LayoutProps = {
  bgColor?: string;
  visualDisplayMode?: string;
  pressEnabled?: boolean;
  isEmpty?: boolean;
  hasOverlay?: boolean;
  showTitleOnly?: boolean;
};

type ContentWrapperProps = {
  full?: boolean;
  pressEnabled?: boolean;
};

export const SubtitleWrapper = styled.p`
  ${paragraphs.body1};
  line-height: 1.4;
  color: currentColor;
  margin: 0;

  @media (min-width: ${breakpoints.base}px) {
    max-width: 70%;
  }
`;

export const BackgroundPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
  background-color: ${colors.pink.base};
  color: ${colors.dark.base};

  svg {
    position: absolute;
    top: 0;
    left: 50%;
    width: ${convertPxToRem(2400)};
    height: ${convertPxToRem(2400 * 0.51)};
    transform: translate3d(-50%, -${convertPxToRem(145)}, 0);
    opacity: 0.8;
  }

  ${SubtitleWrapper} {
    color: ${colors.dark.base};
  }
`;

export const BackgroundMediaWrapper = styled.div`
  ${Image};
  overflow: hidden;
  order: 4;
`;

export const BackgroundMedia = styled.div`
  display: block;
  width: auto;
  border: none;
  outline: none;
`;

export const MediasWrapper = styled.div`
  padding: ${convertPxToRem(27)} 0;

  @media (min-width: ${breakpoints.base}px) {
    background: ${colors.green.base};
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
`;

export const ContentWrapper = styled.div<ContentWrapperProps>`
  display: flex;
  align-items: center;
  padding: ${convertPxToRem(100)} 0 ${convertPxToRem(40)};
  min-height: ${convertPxToRem(260)};
  position: relative;
  z-index: 1;

  @media (min-width: ${breakpoints.xsmall}px) {
    padding: ${convertPxToRem(100)} 0 ${convertPxToRem(80)};
    min-height: ${convertPxToRem(260)};
  }

  @media (min-width: ${breakpoints.base}px) {
    min-height: ${convertPxToRem(360)};
  }

  > ${Container} {
    ${Grid};
    flex: 1;
  }

  ${Container} {
    align-items: center;
  }

  ${({ full }) =>
    full &&
    css`
      min-height: 50vw;

      @media (min-width: ${breakpoints.base}px) {
        min-height: ${convertPxToRem(620)};
        height: 100vh;
      }

      h1 {
        @media (min-width: ${breakpoints.base}px) {
          font-size: ${convertPxToRem(58)};
        }

        @media (min-width: ${breakpoints.normal}px) {
          font-size: ${convertPxToRem(68)};
        }
      }

      ${Container} {
        @media (min-width: ${breakpoints.base}px) {
          padding-bottom: ${convertPxToRem(160)};
        }
      }
    `};
`;

export const ContentInner = styled.div`
  grid-column: 1 / -1;

  @media (min-width: ${breakpoints.xsmall}px) {
    grid-column: 1 / 6;
  }

  @media (min-width: ${breakpoints.base}px) {
    padding-top: ${convertPxToRem(16)};
    grid-column: 1 / 12;
  }
`;

export const CTAsWrapper = styled.ul`
  position: relative;
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  list-style: none;
  padding: ${convertPxToRem(18)} 0 0;
  margin: 0;

  @media (min-width: ${breakpoints.base}px) {
    flex-direction: row;
  }

  li {
    padding: 0 ${convertPxToRem(18)} 0 0;

    :last-child {
      padding-right: 0;
    }
  }
`;

export const Layout = styled.div<LayoutProps>`
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ showTitleOnly }) =>
    showTitleOnly &&
    css`
      ${ContentInner} {
        grid-column: 1 / -1;

        @media (min-width: ${breakpoints.small}px) {
          grid-column: 2 / 6;
        }

        @media (min-width: ${breakpoints.base}px) {
          grid-column: 2 / 12;
        }

        @media (min-width: ${breakpoints.normal}px) {
          grid-column: 3 / 11;
        }
      }
      ${TitleWrapper} {
        @media (min-width: ${breakpoints.small}px) {
          text-align: center;
        }
      }
    `}

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      ${ContentWrapper} {
        display: none;

        @media (min-width: ${breakpoints.base}px) {
          display: flex;
          align-items: flex-end;
          padding-bottom: ${convertPxToRem(80)};
        }
      }
    `}
  ${({ hasOverlay }) =>
    hasOverlay &&
    css`
      ${BackgroundMediaWrapper} {
        ::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          background: linear-gradient(
            90deg,
            ${transparentize(0.6, colors.dark.base)} 20%,
            ${transparentize(1, colors.dark.base)} 75%
          );
        }
      }
    `}
 
  ${BackgroundMediaWrapper} {
    position: relative;
    height: 70vw;
    max-height: ${convertPxToRem(400)};

    ${({ visualDisplayMode, pressEnabled }) =>
      visualDisplayMode === TypeHeaderImgDisplay.RIGHT
        ? css`
            background: none;

            @media (min-width: ${breakpoints.xsmall}px) {
              max-height: unset;
              height: auto;
              margin: 0;
              position: absolute;
              bottom: 0;
              left: 50%;
              top: ${convertPxToRem(70)};
              width: ${convertPxToRem(500)};
              z-index: -1;
            }

            @media (min-width: ${breakpoints.base}px) {
              left: 52%;
              top: ${convertPxToRem(80)};
              width: ${convertPxToRem(800)};
            }

            img {
              @media (min-width: ${breakpoints.xsmall}px) {
                object-fit: contain;
              }
            }
          `
        : css`
            @media (min-width: ${breakpoints.base}px) {
              height: auto;
              max-height: none;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: ${pressEnabled ? convertPxToRem(110) : 0};
              z-index: 0;
            }
          `}
  }

  ${BackgroundMedia} {
    ${({ visualDisplayMode }) =>
      visualDisplayMode === TypeHeaderImgDisplay.RIGHT
        ? css`
            width: 120%;

            @media (min-width: ${breakpoints.xsmall}px) {
              width: auto;
              height: 90%;
              max-height: ${convertPxToRem(400)};
              margin: 0 0 0 55%;
            }
          `
        : css`
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;

            @media (min-width: ${breakpoints.xsmall}px) {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          `}
  }

  & + ${RecruitmentLayout} {
    margin-top: 0;
  }
`;
