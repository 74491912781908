import styled from 'styled-components';

import { SliceWrapperFull } from '@/components/common/slices/Slices.styles';
import {
  breakpoints,
  colors,
  Container,
  convertPxToRem
} from '@proprioo/salatim';

export const RemunerationLayout = styled(SliceWrapperFull)`
  position: relative;
  z-index: 0;
  padding: ${convertPxToRem(56)} 0;
  background: ${colors.green.base};

  @media (min-width: ${breakpoints.base}px) {
    padding: ${convertPxToRem(100)} 0;
  }

  ${Container} {
    @media (max-width: ${breakpoints.xsmall - 1}px) {
      padding: 0;
    }
  }
`;

export const IframeWrapper = styled.div`
  background: ${colors.green.base};
`;

export const IframeContainer = styled.div`
  margin: 0 auto;
  width: ${convertPxToRem(360)};
  height: ${convertPxToRem(660)};
  position: relative;

  @media (min-width: ${breakpoints.small}px) {
    width: ${convertPxToRem(600)};
    height: ${convertPxToRem(520)};
  }

  @media (min-width: ${breakpoints.base}px) {
    width: 100%;
    height: ${convertPxToRem(490)};
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;
