import styled from 'styled-components';

import { breakpoints, convertPxToRem } from '@proprioo/salatim';

export const MediasWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto;
  grid-column-gap: ${convertPxToRem(16)};
  grid-column: 1 / -1;
`;

export const OpinionWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column: 1 / -1;
  white-space: nowrap;
  padding-right: ${convertPxToRem(24)};

  > a {
    display: block;
    flex: 1;
  }

  @media (min-width: ${breakpoints.base}px) {
    grid-column: 1 / 2;
  }

  @media (min-width: ${breakpoints.xlarge}px) {
    padding-right: ${convertPxToRem(56)};
  }
`;

export const LogoWrapper = styled.div<{ isHome: boolean }>`
  display: none;
  grid-column: 1 / -1;
  margin: ${convertPxToRem(24)} 0 0;

  @media (min-width: ${breakpoints.base}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column: 2 / -1;
    margin: 0;
    padding: ${convertPxToRem(16)} ${convertPxToRem(24)};
    border-left: ${convertPxToRem(1)} solid white;
  }

  @media (min-width: ${breakpoints.xlarge}px) {
    padding-left: ${convertPxToRem(56)};
    padding-right: ${convertPxToRem(56)};
  }

  & > svg {
    height: ${convertPxToRem(24)};

    &[data-name='figaro'] {
      width: ${convertPxToRem(91)};

      @media (min-width: ${breakpoints.base}px) {
        width: ${convertPxToRem(145)};
      }
    }

    &[data-name='echos'] {
      width: ${convertPxToRem(86)};

      @media (min-width: ${breakpoints.base}px) {
        width: ${convertPxToRem(137)};
      }
    }

    &[data-name='lemonde'] {
      width: ${convertPxToRem(94)};

      @media (min-width: ${breakpoints.base}px) {
        width: ${convertPxToRem(149)};
      }
    }

    &[data-name='forbes'] {
      width: ${convertPxToRem(70)};

      @media (min-width: ${breakpoints.base}px) {
        width: ${convertPxToRem(113)};
      }
    }

    &[data-name='m6'] {
      width: ${convertPxToRem(32)};

      @media (min-width: ${breakpoints.base}px) {
        width: ${convertPxToRem(42)};
      }
    }
  }
`;
