import styled, { css } from 'styled-components';

import { Grid } from '@/utils/styles';
import { breakpoints, convertPxToRem, paragraphs } from '@proprioo/salatim';

const SliceContentPad = css`
  padding-top: ${convertPxToRem(40)};
`;

export const SliceWrapper = styled.div`
  margin: ${convertPxToRem(56)} 0 0;
  overflow: hidden;

  @media (min-width: ${breakpoints.base}px) {
    margin-top: ${convertPxToRem(100)};
  }

  :last-child {
    margin-bottom: ${convertPxToRem(56)};

    @media (min-width: ${breakpoints.base}px) {
      margin-bottom: ${convertPxToRem(100)};
    }
  }
`;

export const SliceWrapperFull = styled(SliceWrapper)`
  & + & {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const SliceContent = styled.div`
  padding-top: 0;
`;

export const SliceHeader = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.base}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  h2 {
    @media (min-width: ${breakpoints.base}px) {
      max-width: 70%;
    }
  }

  > a {
    margin: ${convertPxToRem(16)} 0 0;

    @media (min-width: ${breakpoints.base}px) {
      margin-top: 0;
    }
  }

  & + ${SliceContent} {
    ${SliceContentPad};
  }
`;

export const SliceIntro = styled.div`
  ${Grid};

  & + ${SliceContent} {
    ${SliceContentPad};
  }
`;

export const SliceIntroContent = styled.p`
  ${paragraphs.body1};
  padding-top: ${convertPxToRem(24)};
  grid-column: 1 / -1;

  @media (min-width: ${breakpoints.base}px) {
    padding-top: ${convertPxToRem(24)};
    grid-column: 1 / 9;
  }
`;

export const SliceFooter = styled.div`
  padding: ${convertPxToRem(40)} 0 0;
`;

export const GridWrapper = styled.div`
  ${Grid};
`;

export const GridChild = styled.div`
  grid-column: auto / span 6;
  margin-top: ${convertPxToRem(32)};

  :first-child {
    margin-top: 0;
  }

  @media (min-width: ${breakpoints.medium}px) {
    grid-column: auto / span 3;
    margin-top: 0;
  }

  @media (min-width: ${breakpoints.base}px) {
    grid-column: auto / span 6;
  }
`;

export const SliceWrapperOffset = styled.div`
  position: relative;
  z-index: 1;
  min-height: 70vh;

  @media (min-width: ${breakpoints.base}px) {
    min-height: unset;
    margin-top: -${convertPxToRem(56)};
    padding-bottom: ${convertPxToRem(80)};
  }
`;
