import { RichTextBlock } from 'prismic-reactjs';
import React, { FC, Fragment } from 'react';

import Arrow from '@/assets/icons/arrow-rounded.svg';
import CustomBreadcrumb from '@/components/common/breadcrumb/CustomBreadcrumb';
import UrlBreadcrumb from '@/components/common/breadcrumb/UrlBreadcrumb';
import CustomLink, {
  formatId,
  getCustomLinkProps
} from '@/components/common/customLink/CustomLink';
import Image from '@/components/common/image/Image';
import Medias from '@/components/common/medias/Medias';
import { PageCta, TypePageHeader } from '@/utils/page';
import { isValidLink, TypeColor } from '@/utils/prismic';
import {
  Button,
  ButtonAppearance,
  ButtonTheme,
  Container,
  H1
} from '@proprioo/salatim';

import { BreadcrumbColor, BreadcrumbElements } from '../breadcrumb/interfaces';
import {
  BackgroundMediaWrapper,
  BackgroundPattern,
  ContentInner,
  ContentWrapper,
  CTAsWrapper,
  Layout,
  MediasWrapper,
  SubtitleWrapper,
  TitleWrapper
} from './MainHeader.styles';

export type MainHeaderProps = {
  headerData: TypePageHeader;
  breadcrumbElements?: BreadcrumbElements;
  hasOverlay?: boolean;
  isEmpty?: boolean;
  pictureFill?: boolean;
  showBreadcrumb?: boolean;
  showTitleOnly?: boolean;
};

const MainHeader: FC<MainHeaderProps> = ({
  headerData = { data: {} },
  breadcrumbElements,
  pictureFill,
  showBreadcrumb = false,
  isEmpty = false,
  hasOverlay = false,
  showTitleOnly = false
}) => {
  const {
    title,
    subTitle,
    hasSearchField,
    bgColor,
    visual = { url: '', dimensions: { width: 0, height: 0 } } as RichTextBlock,
    visualDisplayMode,
    cta = [] as PageCta[],
    pressEnabled
  } = headerData;

  const ctas: PageCta[] = cta.filter(
    ctaItem => ctaItem.label && ctaItem.link && isValidLink(ctaItem.link)
  );

  return (
    <Layout
      bgColor={bgColor}
      hasOverlay={hasOverlay}
      isEmpty={isEmpty}
      pressEnabled={pressEnabled}
      showTitleOnly={showTitleOnly}
      visualDisplayMode={visualDisplayMode}
    >
      <BackgroundPattern data-test="header-pattern" />
      {visual.url && (
        <BackgroundMediaWrapper data-test="header-visual" key={visual.url}>
          <Image fill={pictureFill} imageSrc={visual} priority={true} />
        </BackgroundMediaWrapper>
      )}
      <ContentWrapper full={hasSearchField} pressEnabled={pressEnabled}>
        <Container>
          <ContentInner>
            {showBreadcrumb && (
              <Fragment>
                {breadcrumbElements ? (
                  <CustomBreadcrumb
                    slugs={breadcrumbElements}
                    color={
                      bgColor === TypeColor.BLUE
                        ? BreadcrumbColor.GREEN
                        : BreadcrumbColor.WHITE
                    }
                  />
                ) : (
                  <UrlBreadcrumb />
                )}
              </Fragment>
            )}
            {title && (
              <TitleWrapper data-test="header-title">
                <H1 title={title} />
              </TitleWrapper>
            )}
            {subTitle && (
              <SubtitleWrapper data-test="header-subtitle">
                {subTitle}
              </SubtitleWrapper>
            )}
            {Boolean(ctas.length) && (
              <CTAsWrapper>
                {ctas.map(({ link, label }, index) => (
                  <li key={`${formatId(label)}${index}`}>
                    <CustomLink {...getCustomLinkProps(link, label)}>
                      <Button
                        label={label}
                        appearance={ButtonAppearance.BASIC}
                        theme={
                          bgColor === TypeColor.BLUE
                            ? ButtonTheme.BLACK
                            : ButtonTheme.WHITE
                        }
                        icon={<Arrow />}
                      />
                    </CustomLink>
                  </li>
                ))}
              </CTAsWrapper>
            )}
          </ContentInner>
        </Container>
      </ContentWrapper>

      {pressEnabled && (
        <MediasWrapper data-test="header-medias">
          <Medias />
        </MediasWrapper>
      )}
    </Layout>
  );
};

export default MainHeader;
