import React, { FC } from 'react';

import Echos from '@/assets/icons/echos.svg';
import Figaro from '@/assets/icons/figaro.svg';
import Forbes from '@/assets/icons/forbes.svg';
import Lemonde from '@/assets/icons/lemonde.svg';
import M6 from '@/assets/icons/m6-white.svg';
import { Container } from '@proprioo/salatim';

import OpinionSystem from '../opinionSystem/OpinionSystem';
import { LogoWrapper, MediasWrapper, OpinionWrapper } from './Medias.styles';

export type MediasProps = {
  isHome?: boolean;
};

const Medias: FC<MediasProps> = ({ isHome = true }) => (
  <Container>
    <MediasWrapper data-test="media-list">
      <OpinionWrapper>
        <OpinionSystem id="opinion-link-1" isTotalVisible={true} />
      </OpinionWrapper>
      <LogoWrapper isHome={isHome}>
        <Figaro data-name="figaro" />
        <Echos data-name="echos" />
        <M6 data-name="m6" />
        <Lemonde data-name="lemonde" />
        <Forbes data-name="forbes" />
      </LogoWrapper>
    </MediasWrapper>
  </Container>
);

export default Medias;
