import Image from 'next/image';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import OpinionLogoBlack from '@/assets/images/OpinionSystem.png';
import OpinionLogo from '@/assets/images/OpinionSystemWhite.png';
import { OPINION_LINK } from '@/constants/global';
import { useOpinionSystem } from '@/hooks/useOpinionSystem/useOpinionSystem';
import { getOpinionSystemLocation } from '@/hooks/useOpinionSystem/utils';

import {
  OpinionLayout,
  OpinionRating,
  OpinionTotal
} from './OpinionSystem.styles';

type OpinionSystemProps = {
  id: string;
  isTotalVisible?: boolean;
  displayOnlyRatingStyle?: boolean;
  isOpinionLogoBlack?: boolean;
  opinionLocation?: string;
};

export const formatRating = (rating?: number) =>
  rating !== undefined ? rating.toString().replace('.', ',') : '';

const OpinionSystem: FC<OpinionSystemProps> = ({
  id,
  isTotalVisible = false,
  displayOnlyRatingStyle = false,
  isOpinionLogoBlack = false,
  opinionLocation = null
}) => {
  const { rating, nbTotalOpinions } = useOpinionSystem();
  const { t } = useTranslation();

  return (
    <Fragment>
      {rating && (
        <a
          href={
            opinionLocation
              ? getOpinionSystemLocation(opinionLocation)
              : OPINION_LINK
          }
          rel="noreferrer noopener"
          target="_blank"
          id={id}
        >
          <OpinionLayout displayOnlyRatingStyle={displayOnlyRatingStyle}>
            <Image
              alt="OpinionSystem"
              height={displayOnlyRatingStyle ? 20 : 15}
              loading="lazy"
              src={isOpinionLogoBlack ? OpinionLogoBlack : OpinionLogo}
              width={displayOnlyRatingStyle ? 143 : 107}
            />
            <OpinionRating
              dangerouslySetInnerHTML={{
                __html: displayOnlyRatingStyle
                  ? formatRating(rating)
                  : t('opinionRating', {
                      rating: formatRating(rating),
                      interpolation: { escapeValue: false }
                    })
              }}
            />
            {isTotalVisible && (
              <OpinionTotal>
                {t('andNbOpinions', { count: nbTotalOpinions })}
              </OpinionTotal>
            )}
          </OpinionLayout>
        </a>
      )}
    </Fragment>
  );
};

export default OpinionSystem;
